<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="airlines"
        :search="search"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <template v-if="props.item.show">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">
              <v-btn
                :disabled="props.item.childOf != null"
                @click="handleVisibility(props.item.id)"
                >Toggle</v-btn
              >
            </td>
          </template>
          <template v-else>
            <tr>
              I'm invisible
            </tr>
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar class="accent" flat>
            <v-toolbar-title><h3>Airlines</h3></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="ml-4 mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Airline
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        label="IATA Code"
                        type="text"
                        v-model="editedItem.iata"
                        :rules="iataRules"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        label="Airline Name"
                        type="text"
                        v-model="editedItem.airline"
                        :rules="airlineRules"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        label="Accounting Code"
                        type="text"
                        v-model="editedItem.accounting"
                        :rules="accountingRules"
                        dense
                        outlined
                      ></v-text-field>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary white--text"
                    :disabled="!valid"
                    text
                    @click="saveAirline"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card class="pt-4 pb-4">
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this airline?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary white--text"
                    text
                    @click="deleteAirlineConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editAirline(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteAirline(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getAirlines"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import api from "../../services/axiosconfig";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    dialogDelete: false,
    search: "",
    loading: false,
    headers: [
      { text: "IATA Code", align: "start", value: "iata" },
      { text: "Airline Name", align: "start", value: "airline" },
      { text: "Accounting Code", align: "start", value: "accounting" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
        width: "10%",
        fixed: true,
      },
    ],
    airlines: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      iata: "",
      airline: "",
      accounting: "",
    },
    defaultItem: {
      id: "",
      iata: "",
      airline: "",
      accounting: "",
    },
    iataRules: [
      (v) => !!v || "IATA code is required",
      (v) => v.length == 2 || "IATA code must be 2 characters",
    ],
    airlineRules: [
      (v) => !!v || "Airline name is required",
      (v) => v.length >= 6 || "IATA code must be at least 6 characters",
    ],
    accountingRules: [
      (v) => !!v || "Accounting code is required",
      (v) => v.length == 3 || "Accounting code must be 3 characters",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Airline" : "Edit Airline";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.getAirlines();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  methods: {
    handleVisibility(id) {
      this.items.forEach((element) => {
        if (element.childOf === id) {
          element.show = !element.show;
        }
      });
    },

    editAirline(item) {
      console.log(item);
      this.editedIndex = this.airlines.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteAirline(item) {
      this.editedIndex = this.airlines.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteAirlineConfirm() {
      this.loading = true;
      api
        .delete(`/aejmis/api/airlines/delete/${this.editedItem.id}`)
        .then(() => {
          this.getAirlines();
          this.$toasted.global.optimus_info({
            message: `Airline successfully deleted.`,
          });
          this.loading = false;
          this.closeDelete();
        })
        .catch((error) => {
          this.$toasted.global.optimus_error({
            message: `Could not delete the Airline. Something went wrong.`,
          });
          this.loading = false;
          this.closeDelete();
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveAirline() {
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          this.loading = true;
          try {
            const docUpdated = await api.put(
              `/aejmis/api/airlines/update/${this.editedItem.id}`,
              {
                iata: this.editedItem.iata,
                airline: this.editedItem.airline,
                accounting: this.editedItem.accounting,
              }
            );
            this.getAirlines();
            this.$toasted.global.optimus_info({
              message: `Airline class successfully updated.`,
            });
            this.loading = false;
            this.dialog = false;
            console.log(docUpdated);
          } catch (error) {
            this.$toasted.global.optimus_error({
              message: `Could not update airline. Something went wrong.`,
            });
            this.loading = false;
            this.dialog = false;
          }
        }
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true;

          try {
            const docAdded = await api.post("/aejmis/api/airlines/create", {
              iata: this.editedItem.iata,
              airline: this.editedItem.airline,
              accounting: this.editedItem.accounting,
            });

            this.getAirlines();
            this.$toasted.global.optimus_info({
              message: `Airline successfully added.`,
            });
            this.loading = false;
            this.dialog = false;
            console.log(docAdded);
          } catch (error) {
            this.$toasted.global.optimus_error({
              message: `Could not add airline. Something went wrong.`,
            });
            this.loading = false;
            this.dialog = false;
          }
        }
      }
    },

    async getAirlines() {
      try {
        this.airlines = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/airlines");
        this.airlines = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve information. Try after sometime.",
        });
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  color: rgb(255, 255, 255) !important;
  background-color: #0b2545 !important;
}
.theme--light.v-btn--disabled {
  color: rgb(255, 255, 255) !important;
  background-color: #b8b8b8 !important;
}
</style>
